<template>
  <div>
    <!--<div class="top">-->
    <!--<img src="../assets_ch/back.png" alt="" @click="back">-->
    <!--Back-->
    <!--</div>-->
    <div class="header">
      <div class="img">
        <img :src="videoSet.videoImg" alt="" />
      </div>
      <div class="name-num">
        <p>{{ videoSet.videoTitle }}</p>
        <p>
          <span>{{ videos.length != 0 ? videos.length : 0 }}</span
          >{{$t('publicityMaterials.video.total')}}
        </p>
      </div>
    </div>
    <div class="content">
      <template v-for="(item, index) of videos">
        <a :href="item.externalLink">
          <span v-if="item.externalLink">
            <img :src="item.videoImg" alt="" />
          </span>
        </a>
        <span
          v-if="!item.externalLink"
          :data-url="item.readVideoUrl"
          :data-imgurl="item.videoImg"
          :data-videotitle="item.videoTitle"
          @click="playerVideo(item, 'videoList')"
        >
          <img :src="item.videoImg" alt="" />
        </span>
      </template>
    </div>
    <!-- <div v-if="videoFlag">
          <viewVideo @click="gotoHome($event)" :videoUrl="videoUrls" :videolist="videoList" :videotitle="videoTitle" :imgurl="imgurl"></viewVideo>
      </div> -->
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "videoList",
  components: {},
  data() {
    return {
      videoId: "",
      videos: [],
      videoSet: {},
      videoFlag: false,
      videoUrls: "",
      videoTitle: "",
      videoList: [],
      imgurl: "",
    };
  },
  methods: {

    getvideoList() {
      let that = this,
        data = {
          videoId: this.videoId
        },
        url = "https://official.juhaowu.cn/api/video/videoSet",
        url_en = "https://en.atoshi.info/api/video/videoSet";

      $.ajax({
        type: "post", //提交方式
        url: that.$store.state.isEn ? url_en : url, //路径
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data), // 数据
        success: function (res) {
          //返回数据根据结果进行相应的处理
          if (res.code == 100) {
            let resData = res.data;
            that.videos = resData.videos;
            that.videoSet = resData.videoSet;
            // that.checkwhlist();
          }
        },
      });
    },

    playerVideo(data, routerUrl) {
      // this.videoUrls = currentTarget.dataset.url;
      // this.imgurl = currentTarget.dataset.imgurl;
      // this.videotitle = currentTarget.dataset.videotitle;
      // const { readVideoUrl, videoUrl480, videoUrl720, videoUrl1080, videoImg, videoTitle } = data
      // this.videoUrls = readVideoUrl
      // this.imgurl = videoImg || ""
      // this.videoTitle = videoTitle || ""
      // this.videoList = [
      //     {
      //     name: '流畅',
      //     url: readVideoUrl
      //     },
      //     {
      //     name: '480p',
      //     url: videoUrl480
      //     },
      //     {
      //     name: '720p',
      //     url: videoUrl720
      //     },
      //     {
      //     name: '1080p',
      //     url: videoUrl1080
      //     },
      // ]
      const {
        readVideoUrl,
        videoUrl,
        videoUrl480,
        videoUrl720,
        videoUrl1080,
        videoImg,
        videoTitle,
        youtubeId,
      } = data;
      // readVideoUrl 目前后台返回是有误的 所以先用videoUrl 替代
      this.$router.push({
        path: "/youtubeplayers",
        query: {
          youtubeId: youtubeId,
          videoUrls: videoUrl,
          videotitle: videoTitle,
          imgurl: videoImg,
          routerUrl: routerUrl,
          videoId: this.$route.query.videoId,
          readVideoUrl: videoUrl,
          videoUrl: videoUrl,
          videoUrl480: videoUrl480,
          videoUrl720: videoUrl720,
          videoUrl1080: videoUrl1080,
        },
      });
    },

    gotoHome(target) {
      if (target.nodeName.toLowerCase() !== "video") {
        this.videoFlag = false;
      }
    },
  },
  created() {
    this.videoId = this.$route.query.videoId;
  },
  mounted() {
    this.getvideoList();
  },
};
</script>

<style scoped>
.header {
  margin-top: 0.45rem;
  margin-left: 0.3rem;
  height: 1.95rem;
  display: flex;
}
.img {
  width: 2rem;
  height: 2rem;
  background: url("https://video2.juhaowu.cn/static-web/mobile_img/common/xiangCeBj.png")
    no-repeat;
  background-size: 100%;
  overflow: hidden;
  position: relative;
}
.img img {
  width: 1.95rem;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.name-num {
  position: relative;
  margin-left: 0.33rem;
}
.name-num p:first-of-type {
  position: absolute;
  width: 4rem;
  top: 0;
  font-size: 0.28rem;
  color: #333333;
}
.name-num p:last-of-type {
  width: 4rem;
  font-size: 0.24rem;
  color: #999999;
  position: absolute;
  bottom: 0;
}
.name-num p:last-of-type span {
  font-size: 0.36rem;
  color: #00a0ea;
  padding: 0 0.1rem;
}
.content {
  padding: 0 0.3rem;
  margin-top: 0.8rem;
  display: flex;
  flex-wrap: wrap;
}
.content span {
  display: inline-block;
  overflow: hidden;
  margin-bottom: 0.08rem;
  margin-right: 0.08rem;
  width: 2.24rem;
  height: 1.84rem;
  position: relative;
  border: 1px solid #ddd;
  background: #3e3e3e;
}
.content span img {
  width: 2.24rem;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.content span:nth-of-type(3n) {
  margin-right: 0;
}
</style>
